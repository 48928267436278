
import {Component, Vue} from 'vue-property-decorator';
import {progress} from '@/infrastructure/script/Progress';
import api from '@/infrastructure/api/API';
import {
  EmptyInquiry, EmptyInquiryAnswerRegisterRequest,
  Inquiry, InquiryAnswerRegisterRequest,
  InquiryGetRequest,
} from '@/types/inquiry/Inquiry';
import {InquiryType} from '@/types/inquiry/InquiryType';
import {InquiryAnswerRegisterChecker} from '@/validator/inquiry/InquiryAnswerRegisterChecker';
import moment from 'moment';
import {InquiryStatus} from '@/types/inquiry/InquiryStatus';
import {loginModule} from '@/stores/authentication/Account';
import {AuthorizedAccount} from '@/types/authentication/Account';

@Component
export default class InquiryDetailFormConfirm extends Vue {
  // data
  inquiry: Inquiry = EmptyInquiry;
  request: InquiryGetRequest | null = null;

  messages: string[] = [];

  // computed
  get hasError(): boolean {
    return this.messages.length > 0;
  }

  get loggedInUser(): AuthorizedAccount {
    return loginModule.authorizedAccount;
  }

  get isCompleted(): boolean {
    return this.inquiry.status === InquiryStatus.Answered ||
        this.inquiry.status === InquiryStatus.Completed;
  }

  get answerRequest(): InquiryAnswerRegisterRequest {
    if (sessionStorage.hasOwnProperty('inquiry-detail-form')) {
      return JSON.parse(sessionStorage.getItem('inquiry-detail-form') as string);
    }
    return EmptyInquiryAnswerRegisterRequest;
  }

  get inquiryType(): string {
    if (this.inquiry.type === InquiryType.ToSystem) {
      return 'システム宛';
    }
    return '支所宛';
  }

  // methods
  async initialize(): Promise<void> {
    await this.getDetail();
  }

  async getDetail(): Promise<void> {
    const getInquiry = async (): Promise<void> => {
      if (!this.request) {
        return;
      }
      await api.getInquiry(this.request)
          .then((response: any) => {
            this.inquiry = response;
          });
    };

    await progress(getInquiry);
  }

  async reply(): Promise<void> {
    await this.registerAnswer();
    sessionStorage.removeItem('inquiry-detail-form');
    this.toInquiry();
  }

  async registerAnswer(): Promise<void> {
    const registerAnswer = async (): Promise<void> => {
      await api.registerInquiryAnswer(this.answerRequest)
          .catch((error: any) => {
            if (error.data.message) {
              this.messages = error.data.message;
              return;
            }
          });
    };
    await progress(registerAnswer);
  }

  toInquiry(): void {
    this.$router.push({path: '/inquiry'}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  back(): void {
    this.$router.push({path: `/inquiry/${this.inquiry.seq}`}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  // lifecycle hooks
  created(): void {
    this.request = {
      // @ts-ignore
      inquirySeq: this.$route.params.seq as number,
    };
    this.initialize();
  }
}
